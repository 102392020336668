.price-grid {
    display: grid;
    width: 90%;
    padding: 1rem;
    grid-template-columns: repeat( auto-fit, minmax(420px, 1fr) );
    grid-gap: 10px;
    margin: 0 auto;

    @media (max-width: 512px) {
        grid-template-columns: repeat( auto-fit, minmax(220px, 1fr) );
    }

    @media (min-width: 768px) and (max-width: 850px) {
        grid-template-columns: repeat( auto-fit, minmax(320px, 1fr) );
    }
}

.modal-text {
    text-align: center;
}

.pricing-table {
    .plan-header {
        text-transform: capitalize;
    }
}