.community-banner {
    width: 100vw;
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    mix-blend-mode: multiply;
    padding: 1rem;

    p {
        padding-top: 0.5rem;
        max-width: 100vw;
    }

    a {
        vertical-align: super;
        margin: 1rem;
    }
}