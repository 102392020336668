.codesContainer {
    padding: 1rem;
}

.countInput {
    width: 100px;
}

.codeList {
    margin: 1rem 0;
}