@import '../../index.scss';

.far, .fas {
    margin: 0 5px;
}

.calendar-container {
    padding: 1rem;
}

.modal-card {
    height: 70%;
    width: 80% !important;
}

.community-calendar {
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: white;
}

.select::after {
    z-index: 0 !important;
}

.input[disabled] {
    border: 0px !important;
    color: black !important;
    background-color: transparent !important;
    cursor: auto !important;
}

input:read-only {
    border: 0px;
    background-color: transparent;
}

textarea:read-only {
    border: 0px;
}

select[disabled] {
    color: black !important;
    background-color: transparent !important;
    border: 0px !important;
}