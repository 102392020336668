.root {
    display: grid;
    grid-template-columns: auto;
    justify-items: grid-end;
    grid-column-gap: 1rem;
    width: 50%;
    margin: 0 auto;
}

.social-fields {
    .control {
        display: grid;
        grid-template-columns: 35px auto;
        margin-bottom: 1rem;

        i {
            align-self: center;
        }
    }
}

.communityAdmins {
    width: max-content;
}

.bannerImage {
    margin-bottom: 1rem;
}

.file-cta {
    margin: 0 !important;
}