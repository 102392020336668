@import "../../../../index.scss";

.posts {
    .card {
        width: 100%;
        max-width: 850px;
        margin: 20px 0;

        .card-header {
            display: block;
            text-align: left;
            background-color: white;
            padding: 1rem;

            h1 {
                font-size: 2rem;
            }

            .card-header-icon {
                display: inline;
                float: right;
                font-size: 1.5rem;
            }

            .post-options {
                display: block;
            }
        }

    }
}

.remove-image-badge {
    position: relative;
    right: -10px;
    top: 10px;
    background:grey;
    border-radius: 30px 30px 30px 30px;
    color:white;
    padding: 5px;
    font-size: 15px;
}

.card-images {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
    margin-top: 1rem;
}

.card-border {
    width: 100%;
    height: 5px;
    background-color: $primary-6;
}