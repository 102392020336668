.root {
    padding: 1rem;
}

.metricsContainer {
    display: grid;
    grid-template-columns: 250px 250px 250px;
    grid-template-rows: 200px;
    grid-column-gap: 1rem;

    h1 {
        text-align: center;
    }
}